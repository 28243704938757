import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidateCoupon } from '../../Services';
import { useStateValue } from '../../StateProvider';
import checkAnimation from '../lottiePlayer/animations/check.json';
import LottieCustomPlayer from '../lottiePlayer/LottiePlayer';

const CouponInput = () => {
	const [ formData, setFormData ] = useState({});
	const [ isSaving, setIsSaving ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const [ isStatusVisible, setIsStatusVisible ] = useState(false);
	const [ isStatusSuccess, setIsStatusSuccess ] = useState(false);
	const [ statusMessage, setStatusMessage ] = useState('');
	const [ offerName, setOfferName ] = useState('');
	const { code } = useParams();

	useEffect(
		() => {
			console.log(code);
		},
		[ code ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.coupon) {
			toast.error('Coupon Code is required!');
			return;
		}

		setIsSaving(true);

		let data = {
			coupon   : formData.coupon,
			userCode : code
		};

		const response = await ValidateCoupon(data);

		setIsStatusSuccess(response.isValid);

		if (response.isValid) {
			setStatusMessage(response.message);
			setOfferName(response.offerName);
		} else {
			setStatusMessage(response.message);
			if (response.offerName) {
				setOfferName(response.offerName);
			} else {
				setOfferName('');
			}
		}

		setIsStatusVisible(true);

		setIsSaving(false);
	};

	const reset = () => {
		setIsStatusVisible(false);
		setIsStatusSuccess(false);
		setStatusMessage('');
		setOfferName('');
	};

	return (
		<div className="container">
			<Fragment>
				{!isStatusVisible ? (
					<Fragment>
						{!isSaving ? (
							<Fragment>
								<div>Please Enter a Coupon Code</div>

								<form
									autoComplete="off"
									className="my-3"
									onSubmit={handleSubmit}
								>
									<input
										type="text"
										name="coupon"
										className="form-control form-control-lg text-center"
										placeholder="Enter Coupon Code"
										onChange={handleChange}
										disabled={isSaving}
									/>

									<button
										type="submit"
										className="btn btn-primary mt-3"
										disabled={isSaving}
									>
										<span>Validate Coupon</span>
									</button>
								</form>
							</Fragment>
						) : (
							<Fragment>
								<div className="spinner-border" role="status">
									<span className="visually-hidden">
										Verifying...
									</span>
								</div>
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment>
						{isStatusSuccess ? (
							<Fragment>
								<LottieCustomPlayer
									animation={checkAnimation}
									width={150}
									height={150}
								/>

								<h5 className="display-5">{statusMessage}</h5>
								<div className="text-muted">
									{`For the ${offerName} offer`}
								</div>

								<button
									type="button"
									className="btn btn-outline-primary btn-lg mt-3"
									onClick={() => reset()}
								>
									<span>Check another code</span>
								</button>
							</Fragment>
						) : (
							<Fragment>
								<h5 className="display-5">{statusMessage}</h5>

								<button
									type="button"
									className="btn btn-outline-primary btn-lg mt-3"
									onClick={() => reset()}
								>
									<span>Check another code</span>
								</button>
							</Fragment>
						)}
					</Fragment>
				)}
			</Fragment>
		</div>
	);
};

export default CouponInput;
