import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';

const RestaurantCode = () => {
	const [ formData, setFormData ] = useState({});
	const [ isSaving, setIsSaving ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const navigation = useNavigate();

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!formData.restaurantCode) {
			toast.error('Restaurant code is required to continue');
			return;
		}

		dispatch({
			type : actionTypes.SET_USER,
			user : formData.restaurantCode
		});

		navigation(`/${formData.restaurantCode}`);
	};

	return (
		<div className="container">
			<div>Please Enter Your Restaurant Code</div>

			<form autoComplete="off" className="my-3" onSubmit={handleSubmit}>
				<input
					type="text"
					name="restaurantCode"
					className="form-control form-control-lg text-center"
					placeholder="Enter Restaurant Code"
					onChange={handleChange}
				/>

				<button type="submit" className="btn btn-primary mt-3">
					<span>Confirm</span>
				</button>
			</form>
		</div>
	);
};

export default RestaurantCode;
