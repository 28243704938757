import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import NotFound from './components/404/NotFound';
import CouponInput from './components/couponInput/CouponInput';
import RestaurantCode from './components/restaurantCode/RestaurantCode';
import TermsAndPrivacy from './components/termsAndPrivacy/TermsAndPrivacy';

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<RestaurantCode />} />
					<Route path="/:code" element={<CouponInput />} />
					<Route
						path="/terms-and-privacy"
						element={<TermsAndPrivacy />}
					/>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>

			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
			/>
		</div>
	);
}

export default App;
