import { useLottie } from 'lottie-react';
import { Fragment } from 'react';

const LottieCustomPlayer = ({
	animation,
	width = 150,
	height = 150,
	loop = false
}) => {
	const style = {
		width,
		height,
		marginLeft  : 'auto',
		marginRight : 'auto'
	};
	const options = {
		animationData : animation,
		loop          : loop
	};
	const { View } = useLottie(options, style);

	return <Fragment>{View}</Fragment>;
};

export default LottieCustomPlayer;
