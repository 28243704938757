import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken } from './Token';

// const apiHost = 'https://api.mindfulsms.com/api';
const apiHost = 'https://app-offersapi.realwebsite.com';
// const apiHost = 'https://localhost:7094/api';

export const instance = axios.create({ baseURL: apiHost });

// setup axios to inject the authorization header automatically
// if token is present in store
instance.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

instance.interceptors.response.use(
	async (response) => {
		return response;
	},
	(error) => {
		if (error.response) {
			const { data, status } = error.response;

			switch (status) {
				case 400:
					if (data.errors) {
						for (const key in data.errors) {
							if (data.errors[key]) {
								toast.error(data.errors[key]);
							}
						}
					} else {
						toast.error(data);
					}
					break;
				case 401:
					toast.error('Unauthorized');
					break;
				case 404:
					toast.error('Not found');
					break;
				case 500:
					toast.error('An unknown error has occurred');
					break;
				default:
					toast.error('An unknown error has occurred');
					alert(error);
					break;
			}
		} else {
			toast.error(error);
		}

		return Promise.reject(error);
	}
);

export const HandleAxiosException = (err) => {
	var response = {};
	response['error'] =
		err.response && err.response.data && err.response.data.Message
			? err.response.data.Message
			: err.response.data.title;
	return response;
};

export const UserLogin = async (login) => {
	const response = await instance
		.post(apiHost + '/account/login', login, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetAccount = async () => {
	const response = await instance
		.get(apiHost + '/account/details', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const RegisterUser = async (login) => {
	try {
		const response = await instance
			.post(apiHost + '/account/register', login, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ValidateCoupon = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/offers/coupon', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};
